<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-3 mb-3">
      <button
        v-on:click="changeTab('Account')"
        class="btn btn-secondary w-100 p-4"
      >
        <BaseIcon name="user" type="settings" /> Account
      </button>
    </div>
    <div class="col-3 mb-3">
      <button
        v-on:click="changeTab('Billing')"
        class="btn btn-secondary w-100 p-4"
      >
        <BaseIcon name="billing" type="settings" /> Billing
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button
        v-on:click="changeTab('Companies')"
        class="btn btn-secondary w-100 p-4"
      >
        <BaseIcon name="briefcase" type="settings" /> Companies & warehouses
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button
        v-on:click="changeTab('LineOptions')"
        class="btn btn-secondary w-100 p-4"
      >
        <BaseIcon name="tools" type="settings" /> Tools & modules
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button
        v-on:click="changeTab('Users')"
        class="btn btn-secondary w-100 p-4"
      >
        <BaseIcon name="group" type="settings" /> Team members
      </button>
    </div>
    <div class="col-md-3 mb-3">
      <button
        v-on:click="changeTab('Countries')"
        class="btn btn-secondary w-100 p-4"
      >
        <BaseIcon name="shopping" type="settings" />
        <span>Sales channels</span>
      </button>
    </div>
    <div class="col-md-3">
      <button
        v-on:click="changeTab('integrations')"
        class="btn btn-secondary w-100 p-4"
      >
        <BaseIcon name="puzzle" type="settings" /> Integrations
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import BaseIcon from "../icons/BaseIcon.vue";
export default {
  name: "Start",
  components: { BaseIcon },
  props: {
    tab: String,
  },
  data() {
    return {
      store: useStore(),
    };
  },
  emits: ["update:tab"],
  methods: {
    changeTab(tab) {
      this.$emit("update:tab", tab);
    },
  },
};
</script>
